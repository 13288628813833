/* 
.iti__flag {
    background-image: url("../../../node_modules/intl-tel-input/build/img/flags.png");
  }
   
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-image: url("../../../node_modules/intl-tel-input/build/img/flags@2x.png");
    }
  } */

.intl-tel-input{
    border: 0px;
}